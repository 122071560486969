import React, { useState, useEffect } from 'react';
import ethiotelecomlogo from './Image/Ethio telecom logo.png';
import axios from "axios";
import "./CSS/teledrive.css";
import { useNavigate, useLocation, Link} from 'react-router-dom';
import { FaEye, FaEyeSlash } from "react-icons/fa"
import teledrivelogo from './Image/teledrivelogo.png';
import telebirr from './Image/telebirr.png';
import { BeatLoader } from 'react-spinners';
import { ToastContainer, toast } from 'react-toastify';

function SubscriptionInfo() {
    const Navigate = useNavigate();
    const [mandateLists, setProductList] = useState([]);
    const [subscribtionPage, setSubscribtionPage] = useState(false);
    const [subscriptionMessage, setSubscriptionMessage] = useState();
    const [successMessage, setSuccessMessage] = useState();
    const [subscriptionMessagePage, setSubscriptionMessagePage] = useState(false);
    const [userSubscriptionPage, setUserSubscriptionPage] = useState(false);
    const [userSubscriptionDetail, setUserSubscriptionDetailPage] = useState(true)
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [confimationMessage, setConfimationMessage] = useState("0");
    const [suppAppToken, setSuppAppToken] = useState()
    const Location = useLocation();
    const subscriptionUserInfo = Location.state?.subscriptionUserInfo;
    console.log("subscriptionInfo subscriptionUserInfo", subscriptionUserInfo)
    
    const customerNumber = subscriptionUserInfo.customerInfo.biz_content.identifier
    const customerName = subscriptionUserInfo.customerInfo.biz_content.nickName
    const subPriductInfo =subscriptionUserInfo.subscriptionInfo.name
    const subPaymentAmount = subscriptionUserInfo.subscriptionInfo.price
    const subProductId = subscriptionUserInfo.subscriptionInfo.data.productId
    const merchantContractNumber = subscriptionUserInfo.subscriptionInfo.data.contractNo
    const ProductDes = subscriptionUserInfo.subscriptionInfo.ProductDes
    const mandateId = subscriptionUserInfo.subscriptionInfo.data.mandateId
    const contractId = subscriptionUserInfo.subscriptionInfo.data.contractId
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer zMC320eRyTIKLDAxb"
        },
    })
    const CustomToast = ({ message, backgroundColor }) => {
      return (
          <div className='toast-error'>
              {message}
          </div>
      );
  };

  useEffect(() => {
    try {
        const MerchantAppID = process.env.REACT_APP_MERCHANT_APP_ID;
        window.handleinitDataCallback = (token) => {
            setSuppAppToken(token)
        };
        let obj = JSON.stringify({
            functionName: "js_fun_h5GetAccessToken",
            params: {
                appid: MerchantAppID,
                functionCallBackName: "handleinitDataCallback",
            },
        });
        window.consumerapp.evaluate(obj);
    } catch { }
}, [])




const handleUnsubscribe = () => {
  setIsConfirmed(true)
  setConfimationMessage("Are you sure you want to cancel the subscription?")
  }

const handlViewSubscription = () => {
  setUserSubscriptionDetailPage(true)
  setUserSubscriptionPage(false)
}
const handleOkUnsubscribe = () => {
    Navigate("/h5/Teledrive")
  }


  const handleCloseConfirmPopup = () => {
    setIsConfirmed(false)
  }
  
  const handleConfirmChageStatus = () => {
    setIsLoading(true)
    setIsConfirmed(false)
    setUserSubscriptionPage(false)
    try {
      axiosInstance.post("/api/SubscriptionCancel", {
        CustomerNumber: customerNumber,
        productId: subProductId,
        mctContractNo: merchantContractNumber,
        mandateId: mandateId,
        contractId:contractId,
        authToken: suppAppToken
      },{
        timeout: 20000
      }).then(res => {
        setIsLoading(false)
        if (res.data.status == "success") {

          setSubscriptionMessage("You have successfully unsubscribed the service. You can subscribe at any time again")
          setUserSubscriptionDetailPage(false)
          setUserSubscriptionPage(false)
          setSubscribtionPage(false)
          setSubscriptionMessagePage(true)
          setSuccessMessage("True")
          console.log("CancelMandate", res.data)
        }
        else {
          setSubscriptionMessage("Error")
          setUserSubscriptionDetailPage(false)
          setUserSubscriptionPage(false)
          setSubscriptionMessagePage(true)
          setSubscribtionPage(false)
          setSuccessMessage("False")
          console.log("CancelMandate", res.data)
        }
      }).catch((error) => {
        toast(<CustomToast message="Connection error, please try again" backgroundColor="eb6c63" />, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          setIsLoading(false)
    })

    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  
  }

const handleSetPassword = () => {
  const link = "https://onelink.to/z6v45h"
  window.location.href = link
    }
    
return (
  <div className="teledrivecontainer">
  <div className="mainpage">
  <div className="header-container">
          <div className="header-div1">
            <img width={6} height={5}
              className="ethio-icon"
              alt=""
              src={ethiotelecomlogo}
            />
          </div>
          <div className="header-div1">
            <img width={6} height={4}
              className="teledrive-icon"
              alt=""
              src={teledrivelogo}
            />
          </div>
          <div className="header-div1">
            <img width={6} height={6}
              className="telebirr-icon"
              alt=""
              src={telebirr}
            />
          </div>
          {/* <div><button onClick={test}>Test</button></div> */}
        </div>
        {userSubscriptionPage && (
          <>
            <div style={{ display: "flex", width: "90%", fontSize: "14px", alignContent: "flex-start", paddingTop: "20px" }}>Subscription</div>
            <div style={{ width: "95%" }} className="subMainPage" onClick={handlViewSubscription}>
              <div className="subscriptioninfo">
                <tabl>
                  <tr><td className="subscriptioninfotitle">{"Subscribed Product: "}</td><td className="subscriptioninfocontent">{subPriductInfo}</td></tr>
                  <tr><td className="subscriptioninfotitle">{"Monthly Paymnet: "}</td><td className="subscriptioninfocontent">{subPaymentAmount} {" Birr"}</td></tr>
                </tabl>
              </div>
            </div>
          </>
        )}

        {userSubscriptionDetail && (
          <>
            <div style={{ display: "flex", width: "90%", fontSize: "14px", alignContent: "flex-start", paddingTop: "20px" }}>Subscription Detail</div>
            <div style={{ width: "95%" }} className="subDetailPage">
              <div className="subscriptionDetailinfo">
                <tabl>
                <tr><td className="subscriptioninfotitle">{"Customer Mobile Number: "}</td><td className="subscriptioninfocontent">{customerNumber}</td></tr>
                <tr><td className="subscriptioninfotitle">{"Customer Name: "}</td><td className="subscriptioninfocontent">{customerName}</td></tr>
                  <tr><td className="subscriptioninfotitle">{"Subscribed Package: "}</td><td className="subscriptioninfocontent">{subPriductInfo}</td></tr>
                  <tr><td className="subscriptioninfotitle">{"Monthly Paymnet: "}</td><td className="subscriptioninfocontent">{subPaymentAmount} {" ETB"}</td></tr>
                  <tr><td className="subscriptioninfotitle">{"Sub Contract Number: "}</td><td className="subscriptioninfocontent">{merchantContractNumber}</td></tr>
                  {/* <tr><td className="subscriptioninfotitledi">{"Product Description: "}</td><td className="subscriptioninfocontent"></td></tr> */}
                  {/* <tr><td colSpan={2} className="subscriptioninfocontentdisc">{ProductDes}</td></tr> */}
                </tabl>
              </div>
              {/* <div className="btn_subscribe">
                                        <button className="btn-conatiner" onClick={handleSetPassword1}>
                                            <div className="btn-div">
                                                <div className="next">setPassword</div>
                                            </div>
                                        </button>
                                    </div> */}
              <div className="btn_subscribe"><button className="btn-conatiner" onClick={handleSetPassword}>
                <div className="btn-div">
                  <div className="next">teledrive Login</div>
                </div>
              </button>
              </div>
              <div className="btn_subscribe"><button className="btn-conatiner" onClick={handleUnsubscribe}>
                <div className="btn-div">
                  <div className="next">Unsubscribe</div>
                </div>
              </button>
              </div>
            </div>
          </>
      )}
        {subscriptionMessagePage &&
          (
            <div className="Message_page_main">
              <div className={successMessage == "True" ? 'Message_page_success' : 'Message_page_failed'}>

                <label>{subscriptionMessage}</label>
              </div>
              <div className="btn_sbscription">
                <button className="btn-conatiner" onClick={handleOkUnsubscribe}>
                  <div className="btn-div">
                    <div className="next">Ok</div>
                  </div>
                </button>
              </div>
            </div>
          )
        }
        {isLoading &&
            <div className="popup">
                <BeatLoader animation="border" size={30} color={"#53cd0d"} />
            </div>
        }
        {isConfirmed &&
                    <div className="popupConfirmation">
                        <div className="popupConfirmationchild">
                            <div className='popupConfirmationHead'>
                                Confirmation  {/*  <img width={20} style={{cursor:"pointer"}} src={Close} onClick={handleCloseConfirmPopup}></img> */}
                            </div>
                            <div className='popupConfirmationBody'>
                                <label>{confimationMessage}</label>
                            </div>
                            <div className='popupConfirmationfooter'>

                                <button className='btn' onClick={handleCloseConfirmPopup} >No</button>
                                <button className='btn' onClick={handleConfirmChageStatus}>Yes</button>
                            </div>
                        </div>
                    </div>}
    </div>
    </div>
);
};

export default SubscriptionInfo;
