import React from 'react';
import ReactDOM from 'react-dom/client';
import { createHashRouter, RouterProvider } from "react-router-dom";
import Teledrive from './teledrive';
import Teledrivesubscribesuccess from './teledrivesubscribesuccess';
import SetPassword from './setPassword';
import SubscriptionInfo from './subscriptionInfo';
import TeledriveUnsubscribe from './TeledriveUnsubscribe';
import CheckSubscription from './checkSubscription';
import Info from './Info';
const router = createHashRouter([
  
  {
    path: "/",
    element: <Teledrive />,
  },
  {
    path: "/h5/Teledrive",
    element: <Teledrive />,
  },
  {
    path: "/h5/teledrivesubscribesucces",
    element: <Teledrivesubscribesuccess/>,
  },
  {
    path: "/h5/Info",
    element: <Info/>,
  },
  {
    path: "/h5/SetPassword",
    element: <SetPassword/>,
  },
  {
    path: "/h5/SubscriptionInfo",
    element: <SubscriptionInfo/>,
  },
  {
    path: "/h5/TeledriveUnsubscribe",
    element: <TeledriveUnsubscribe/>,
  },
  {
    path: "/h5/CheckSubscription",
    element: <CheckSubscription/>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);



// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(

//   <RouterProvider router={router} />
  
  
//   );



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

