import React, { useState, useEffect } from 'react';
import ethiotelecomlogo from './Image/Ethio telecom logo.png';
import axios from "axios";
import "./CSS/teledrive.css";
import { useNavigate, useLocation } from 'react-router-dom';
import { FaEye, FaEyeSlash } from "react-icons/fa"
import teledrivelogo from './Image/teledrivelogo.png';
import telebirr from './Image/telebirr.png';
import { BeatLoader } from 'react-spinners';
import { ToastContainer, toast } from 'react-toastify';
const teledrivePlayStore = process.env.REACT_APP_TELEDRIVE_LOGIN


function TeledriveUnsubscribe() {
    const Navigate = useNavigate();
    const [subscriptionMessage, setSubscriptionMessage] = useState();
    const [successMessage, setSuccessMessage] = useState();
    const [subscriptionMessagePage, setSubscriptionMessagePage] = useState(false);
    const [teledriveSubPage, setTeledriveSubPage] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [confimationMessage, setConfimationMessage] = useState("0");
    const [suppAppToken, setSuppAppToken] = useState()
    const Location = useLocation();
    const subscriptionUserInfo = Location.state?.subscriptionUserInfo;
    console.log("subscriptionInfo subscriptionUserInfo", subscriptionUserInfo)
    const customerNumber = subscriptionUserInfo.customerInfo.biz_content.identifier
    const subProductId = subscriptionUserInfo.subscriptionInfo.data.productId
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer zMC320eRyTIKLDAxb"
        },
    })
    const CustomToast = ({ message, backgroundColor }) => {
        return (
            <div className='toast-error'>
                {message}
            </div>
        );
    };
    useEffect(() => {
        try {
            const MerchantAppID = process.env.REACT_APP_MERCHANT_APP_ID;
            window.handleinitDataCallback = (token) => {
                setSuppAppToken(token)
            };
            let obj = JSON.stringify({
                functionName: "js_fun_h5GetAccessToken",
                params: {
                    appid: MerchantAppID,
                    functionCallBackName: "handleinitDataCallback",
                },
            });
            window.consumerapp.evaluate(obj);
            console.log("Consumer app", obj)
        } catch { }
    }, [])
    const handleUnsubscribe = () => {
        setIsConfirmed(true)
        setConfimationMessage("Are you sure you want to cancel the subscription?")
    }


    const handleOkUnsubscribe = () => {
        Navigate("/h5/Teledrive")
    }
    const teledriveLogin = () => {
        const link = "https://onelink.to/z6v45h"
        window.location.href = link

    }

    const handleCloseConfirmPopup = () => {
        setIsConfirmed(false)
      }
      
      const handleConfirmChageStatus = () => {
        setIsConfirmed(false)
        setIsLoading(true)
        try {
            axiosInstance.post("/api/TeledriveSubscribeCancel", {
                CustomerNumber: customerNumber,
                productId: subProductId,
                authToken: suppAppToken
            }).then(res => {
                setIsLoading(false)
                if (res.data.status == "success") {
                    setTeledriveSubPage(false)
                    setSubscriptionMessage("You have successfully unsubscribed the service. You can subscribe at any time again")
                    setSubscriptionMessagePage(true)
                    setSuccessMessage("True")
                    console.log("CancelMandate", res.data)
                }
                else {
                    setTeledriveSubPage(false)
                    setSubscriptionMessage("Error")
                    setSubscriptionMessagePage(true)
                    setSuccessMessage("False")
                    console.log("CancelMandate", res.data)
                }
            });
        } catch (error) {
            setIsLoading(false)
            console.log(error)
        }
      
      }

    return (
        <div className="teledrivecontainer">
            <div className="mainpage">
            <div className="header-container">
          <div className="header-div1">
            <img width={6} height={5}
              className="ethio-icon"
              alt=""
              src={ethiotelecomlogo}
            />
          </div>
          <div className="header-div1">
            <img width={6} height={4}
              className="teledrive-icon"
              alt=""
              src={teledrivelogo}
            />
          </div>
          <div className="header-div1">
            <img width={6} height={6}
              className="telebirr-icon"
              alt=""
              src={telebirr}
            />
          </div>
          {/* <div><button onClick={test}>Test</button></div> */}
        </div>
                {teledriveSubPage &&
                    (
                        <>
                            
                            <div style={{ width: "95%" }} className="teledriveSubDetailPage">
                                <div className="Message_sub">
                                    You are already registered for teledrive via SMS, you can unsubscribe  and subscribe again via telebirr SuperApp to change your monthly payment to telebirr.
                                </div>
                                
                                <div className="btn_subscribe">
                                        <button className="btn-conatiner" onClick={teledriveLogin}>
                                            <div className="btn-div">
                                                <div className="next">teledrive Login</div>
                                            </div>
                                        </button>
                                    </div>
                                <div className="btn_subscribe">
                                    <button className="btn-conatiner" onClick={handleUnsubscribe}>
                                        <div className="btn-div">
                                            <div className="next">Unsubscribe</div>
                                        </div>
                                    </button>
                                </div>

                                   
                                </div>
                            </>
          )}
                            {subscriptionMessagePage &&
                                (
                                    <div className="Message_page_main">
                                        <div className={successMessage == "True" ? 'Message_page_success' : 'Message_page_failed'}>

                                            <label>{subscriptionMessage}</label>
                                        </div>
                                        <div className="btn_sbscription">
                                            <button className="btn-conatiner" onClick={handleOkUnsubscribe}>
                                                <div className="btn-div">
                                                    <div className="next">Ok</div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                )
                            }
                            {isLoading &&
                                <div className="popup">
                                    <BeatLoader animation="border" size={30} color={"#53cd0d"} />
                                </div>
                            }
                             {isConfirmed &&
                    <div className="popupConfirmation">
                        <div className="popupConfirmationchild">
                            <div className='popupConfirmationHead'>
                                Confirm acount status change  {/*  <img width={20} style={{cursor:"pointer"}} src={Close} onClick={handleCloseConfirmPopup}></img> */}
                            </div>
                            <div className='popupConfirmationBody'>
                                <label>{confimationMessage}</label>
                            </div>
                            <div className='popupConfirmationfooter'>

                                <button className='btn' onClick={handleCloseConfirmPopup} >No</button>
                                <button className='btn' onClick={handleConfirmChageStatus}>Yes</button>
                            </div>
                        </div>
                    </div>}
                        </div>
            </div>
            );
};

            export default TeledriveUnsubscribe;
