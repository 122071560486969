import React from 'react'
import logo from './Image/teledrivelogo.jpg';
import ethiotelecomlogo from './Image/Ethio telecom logo.png';

const teledrivesubscribesuccess = () => {
  return (
    <div className="teledrivecontainer">
    <div className="mainpage">
      <div className="header-container">
        <div className="header-div1">
          <img width={10} height={10}
            className="ethio-icon"
            alt=""
            src={ethiotelecomlogo}
          />
        </div>
        <div className="header-div">

          <img width={10} height={10}
            className="teledrive-icon"
            alt=""
            src={logo}
          />
        </div>
      </div>
    <div>Info</div>
    </div>
    </div>
  )
}

export default teledrivesubscribesuccess
