import React, { useState, useEffect } from 'react';
import ethiotelecomlogo from './Image/Ethio telecom logo.png';
import axios from "axios";
import { useNavigate, useLocation } from 'react-router-dom';
import { FaEye, FaEyeSlash } from "react-icons/fa"
import teledrivelogo from './Image/teledrivelogo.png';
import telebirr from './Image/telebirr.png';
import { BeatLoader } from 'react-spinners';
import { ToastContainer, toast } from 'react-toastify';

const teledrivePlayStore = process.env.REACT_APP_TELEDRIVE_LOGIN
function CheckSubscription() {
    const Navigate = useNavigate();
    const Location = useLocation();
    const [suppAppToken, setSuppAppToken] = useState()
    const CheckSub = Location.state?.CheckSub;
    const mctContractNo = CheckSub.mctContractNo
    const customerNumber = CheckSub.customerNumber
    console.log("checkSubscription CheckSub", CheckSub)
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'Content-Type': 'application/json',
            authToken: suppAppToken
        },
    })
    const [isLoading, setIsLoading] = useState(false);
    const [tryAgain, setTryAgain] = useState(true)
    const CustomToast = ({ message, backgroundColor }) => {
        return (
          <div className='toast-error'>
            {message}
          </div>
        );
      };
    const TrayAgain =()=>{
        subStatusResponse()
        setTryAgain(false)
      }
      async function subStatusResponse() {
        setIsLoading(true);
        try {
          const response = await axiosInstance.post("/api/teledriveStatus", {
            mctContractNo: mctContractNo,
            phoneNumber: customerNumber
          });
          setIsLoading(false);
          const status = response.data.status;
          console.log("response", status)
          if (status == "Success") {
            Navigate("/h5/SetPassword", { state: { customerNumber } });
          }
          else {
            const CheckSub = {
              customerNumber:customerNumber,
              mctContractNo:mctContractNo
            }
            toast(<CustomToast message="Your Subscription is not successful" backgroundColor="eb6c63" />, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              })
            Navigate("/h5/Teledrive")
          }
        } catch (error) {
        } finally {
          // Re-enable subscribe button after processing
        }
      }

    return (
        <div className="teledrivecontainer">
        <ToastContainer />
        <div className="mainpage">
        <div className="header-container">
          <div className="header-div1">
            <img width={6} height={5}
              className="ethio-icon"
              alt=""
              src={ethiotelecomlogo}
            />
          </div>
          <div className="header-div1">
            <img width={6} height={4}
              className="teledrive-icon"
              alt=""
              src={teledrivelogo}
            />
          </div>
          <div className="header-div1">
            <img width={6} height={6}
              className="telebirr-icon"
              alt=""
              src={telebirr}
            />
          </div>
          {/* <div><button onClick={test}>Test</button></div> */}
        </div>

            {isLoading &&
                <div className="loaderMainPage">
                    <BeatLoader animation="border" size={30} color={"#53cd0d"} />
                </div>
            }
            {tryAgain &&
                <div className="popup">
                    <div className="popup-wait">
                        <div className="popup-text_try">Subscription status Query Failed</div>
                        <div>
                            <button className="btn_try" onClick={TrayAgain}>Try again</button>
                        </div>
                    </div>
                </div>
            }
        </div>
        </div>
    );
}

export default CheckSubscription;
