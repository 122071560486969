import React, { useState, useEffect } from 'react';
import ethiotelecomlogo from './Image/Ethio telecom logo.png';
import axios from "axios";
import { useNavigate, useLocation } from 'react-router-dom';
import { FaEye, FaEyeSlash } from "react-icons/fa"
import teledrivelogo from './Image/teledrivelogo.png';
import { BeatLoader } from 'react-spinners';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const teledrivePlayStore = process.env.REACT_APP_TELEDRIVE_LOGIN
function ChangePassword() {
    const Navigate = useNavigate();
    const Location = useLocation();
    const [suppAppToken, setSuppAppToken] = useState()
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'Content-Type': 'application/json',
             authToken: suppAppToken
        },
    })
    const customerPhoneNumber = Location.state?.customerNumber;

    const CustomToast = ({ message, backgroundColor }) => {
        return (
            <div className='toast-error'>
                {message}
            </div>
        );
    };

    const [isLoading, setIsLoading] = useState(false);
    const customerNumber = sessionStorage.getItem("customerNumber");
    const [newpassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordStrength, setPasswordStrength] = useState('');
    const [passwordPolicy, setPasswordPolicy] = useState('');
    const [passwordPMessage, setPasswordPMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'newPassword') {
            setNewPassword(value);
            checkPasswordStrength(value);
        } else if (name === 'confirmPassword') {
            setConfirmPassword(value);
        }

    };
    useEffect(() => {
        try {
            const MerchantAppID = process.env.REACT_APP_MERCHANT_APP_ID;
            window.handleinitDataCallback = (token) => {
                setSuppAppToken(token)
            };
            let obj = JSON.stringify({
                functionName: "js_fun_h5GetAccessToken",
                params: {
                    appid: MerchantAppID,
                    functionCallBackName: "handleinitDataCallback",
                },
            });
            window.consumerapp.evaluate(obj);
        } catch { }
    }, [])

    const checkPasswordStrength = (password) => {
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':\\|,.<>/?`~]/.test(password);
        let strength = 'Weak';
        setPasswordPolicy("NotAllowed")
        if (password.length <= 5) 
        {
            strength = 'Weak';
            setPasswordPolicy("NotAllowed")
            setPasswordStrength(strength);
        }
      else if (password.length >= 6) 
        {
            axiosInstance.post("/api/ws", {
                password: newpassword,
            }).then(res => {
                
                const PasswordMessage = res.data
                const passwordScore = res.data.data.score
                const passwordMinimal = res.data.data.minimal
                if (PasswordMessage.status == "success" && passwordScore < passwordMinimal) {
                   
                    strength = 'Weak';
                    setPasswordPolicy("NotAllowed")
                    setPasswordStrength(strength);
                   // console.log("PasswordMessage", PasswordMessage.status, PasswordMessage.data.score, strength)
                }
                else if (PasswordMessage.status == "success" && passwordScore == passwordMinimal) {
                   
                    strength = 'Medium';
                    setPasswordPolicy("Allowed")
                    setPasswordStrength(strength);
                   // console.log("PasswordMessage", PasswordMessage.status, PasswordMessage.data.score, strength)
                }
                else if (PasswordMessage.status == "success" && passwordScore > passwordMinimal) {
                    
                    strength = 'Strong';
                    setPasswordPolicy("Allowed")
                    setPasswordStrength(strength);
                   // console.log("PasswordMessage", PasswordMessage.status, PasswordMessage.data.score, strength)
                }
                else {
                    
                }

            }).catch((error) => {
                
            })
            
        }
        console.log("strength", strength)
        
    };
    const handleSubmit = (event) => {
        event.preventDefault();

        if (passwordPolicy == "Allowed") {

            if (newpassword == confirmPassword) {
                setPasswordPMessage("")
                console.log('Passwords submitted successfully!', newpassword,"/", customerNumber);
                try {
                    setIsLoading(true)
                    axiosInstance.post("/api/Set_Password", {
                        password: newpassword,
                        customerNumber: customerPhoneNumber
                    },{
                        timeout: 20000
                      }).then(res => {
                        setIsLoading(false)
                        const setPasswordMessage = res.data.status
                        if (setPasswordMessage == "Success") {
                            window.location.href = teledrivePlayStore;
                        }
                        else if (setPasswordMessage == "failed") {
                            toast(<CustomToast message="Connection error, please try again" backgroundColor="eb6c63" />, {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            })
                        }
                        else {
                            setPasswordPMessage("Password must be at least 6 characters long and cannot contain sequences of consecutive numbers")
                        }

                    }).catch((error) => {
                        toast(<CustomToast message="Connection error, please try again" backgroundColor="eb6c63" />, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          })
                          setIsLoading(false)
                    })

                } catch (error) {

                } finally {
                    // Re-enable subscribe button after processing
                }
            }
            else {
                setPasswordPMessage("Passwords do not match!")
                return;
            }

        }
        else {
            setPasswordPMessage("Password must be at least 8 characters that contains letters, numbers and special characters")
        }
    };
    const getStrengthColor = (strength) => {
        switch (strength) {
            case 'Weak':
                return '#FF0000';
            case 'Medium':
                return '#FFA500';
            case 'Strong':
                return '#00FF00';
            default:
                return '#f4f4f4';
        }
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <div className="change-password-container">
            <div className="setPasswordlogo">
                <img width={10} height={10}
                    className="ethio-icon"
                    alt=""
                    src={ethiotelecomlogo}
                />
            </div>


            <div className='setPasswordTitle'>
                <div className="teledrive-text"><h2>teledrive</h2></div>
                <div className="change-password-text"><h2>Set Password</h2></div>
            </div>
            <form className='password-input-container' onSubmit={handleSubmit}>
                <div className="password-input">
                    <label htmlFor="newPassword">Enter your new password:</label>
                    <div className='password-input-eye'>
                        <input
                            className='password'
                            type={showPassword ? 'text' : 'password'}
                            name="newPassword"
                            id="newPassword"
                            value={newpassword}
                            onChange={handleChange}
                        />
                        <div className='password-eye' onClick={togglePasswordVisibility}>
                            {!showPassword ? (<FaEye color="gray" size={15} ></FaEye>
                            ) : (

                                <FaEyeSlash color="gray" size={15} ></FaEyeSlash>
                            )}
                        </div>
                    </div>
                </div>

                <div>
                    <div className="password-strength">
                        <div>
                            Strength:
                        </div>
                        <div style={{
                            border: "0.5px solid #f1eeee", backgroundColor: "#f4f4f4", borderRadius: "10px",
                            width: 60,
                            height: "5px"
                        }}>
                            <div className="strength-bar">
                                <div
                                    style={{
                                        width: `${passwordStrength === 'Weak' ? 35 : passwordStrength === 'Medium' ? 60 : 100}%`,
                                        backgroundColor: getStrengthColor(passwordStrength),
                                        height: '5px',
                                        border: "0px solid #f1eeee",
                                        borderRadius: "5px",
                                        opacity: 0.5,
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div className="password-input">
                        <label htmlFor="confirmPassword">Repeat your new password:</label>
                        <div className='password-input-eye'>
                            <input
                                className='password-input1'
                                type={showConfirmPassword ? 'text' : 'password'}
                                name="confirmPassword"
                                id="confirmPassword"
                                value={confirmPassword}
                                onChange={handleChange}
                            />
                            <div className='password-eye' onClick={toggleConfirmPasswordVisibility}>
                                {!showConfirmPassword ? (<FaEye color="gray" size={15} ></FaEye>
                                ) : (

                                    <FaEyeSlash color="gray" size={15} ></FaEyeSlash>
                                )}


                            </div>
                        </div>
                    </div>
                    {passwordPMessage && (
                        <div style={{ color: "Red", fontSize: "12px" }}>{passwordPMessage}</div>
                    )
                    }
                </div>
                <div className="button-container">
                    <button type="submit">Set Password</button>
                    <div className='set-password-cancel'>Cancel</div>
                </div>
            </form>
            {isLoading &&
                <div className="loaderMainPage">
                    <BeatLoader animation="border" size={30} color={"#53cd0d"} />
                </div>
            }
        </div>
    );
}

export default ChangePassword;
